






















































































import { formatCurrency, convertCurrencyToNumber } from '@/utility/utils';
import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox
  }
})
export default class RevenueByMonthTable extends Vue {
  @Prop({ type: String, required: true })
  tableCornerHeader!: string;

  @Prop({ type: Array, required: true })
  data!: Array<{
    dataObject: string;
    revenue1: number;
    revenue2: number;
    revenue3: number;
    revenue4: number;
    revenue5: number;
    revenue6: number;
    revenue7: number;
    revenue8: number;
    revenue9: number;
    revenue10: number;
    revenue11: number;
    revenue12: number;
    isMonthTotal: boolean;
  }>;

  @Prop({ type: Array, required: true })
  monthRevenues!: Array<{
    month: number;
    total: number;
  }>;

  @Prop({ type: String, required: false })
  colorVariant: string | null | undefined;

  @Prop({ type: Number, required: true })
  year!: number;

  //Vuex

  // Methods will be component methods
  formatCurrency = formatCurrency;

  get fields() {
    const thClass = 'th-color clickable-icon';
    return [
      {
        key: 'dataObject',
        label: this.tableCornerHeader,
        thClass: 'th-color',
        thStyle: { width: '13%' },
        variant: this.colorVariant
      },
      {
        key: 'revenue1',
        label: '1',
        thClass: thClass
      },
      {
        key: 'revenue2',
        label: '2',
        thClass: thClass
      },
      {
        key: 'revenue3',
        label: '3',
        thClass: thClass
      },
      {
        key: 'revenue4',
        label: '4',
        thClass: thClass
      },
      {
        key: 'revenue5',
        label: '5',
        thClass: thClass
      },
      {
        key: 'revenue6',
        label: '6',
        thClass: thClass
      },
      {
        key: 'revenue7',
        label: '7',
        thClass: thClass
      },
      {
        key: 'revenue8',
        label: '8',
        thClass: thClass
      },
      {
        key: 'revenue9',
        label: '9',
        thClass: thClass
      },
      {
        key: 'revenue10',
        label: '10',
        thClass: thClass
      },
      {
        key: 'revenue11',
        label: '11',
        thClass: thClass
      },
      {
        key: 'revenue12',
        label: '12',
        thClass: thClass
      }
    ];
  }

  get totalByYear() {
    return this.monthRevenues?.reduce((n, { total }) => n + total, 0) ?? 0;
  }

  get revenueData() {
    let mothRevenue = {
      dataObject: 'Tổng tháng',
      revenue1: 0,
      revenue2: 0,
      revenue3: 0,
      revenue4: 0,
      revenue5: 0,
      revenue6: 0,
      revenue7: 0,
      revenue8: 0,
      revenue9: 0,
      revenue10: 0,
      revenue11: 0,
      revenue12: 0,
      isMonthTotal: true
    };

    this.monthRevenues.forEach((s) => {
      const monthKey = 'revenue' + s.month;
      mothRevenue[monthKey] = s.total;
    });

    const revenueData = [...this.data, mothRevenue];
    return revenueData;
  }

  headerClicked(item, index, event) {
    const month = index.label;
    console.log({ event });
    this.$emit('onHeaderClicked', month);
  }
}
