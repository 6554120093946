

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormSelectOption
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  EmployeeListViewModel,
  OrderPaymentStatus,
  RevenueStatisticsByEmployee,
  SelectModelOfString
} from '@/api/api';
import {
  enumToDropdownOptions,
  formatCurrency,
  formatDate
} from '@/utility/utils';
import RevenueByMonthTable from './RevenueByMonthTable.vue';
import RevenueByProductTable from './RevenueByProductTable.vue';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeAction,
  EmployeeState
} from '@/store/employee/employee.module-types';
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';
import ApiClientFactory from '@/api/apiClientFactory';
import AuthStorageService from '@/utility/account/auth.storage.service';
import DebtSummaryModal from './DebtSummaryModal.vue';

// Define the component in class-style
@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    RevenueByMonthTable,
    RevenueByProductTable,
    vSelect,
    DebtSummaryModal
  },
  computed: {
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.employees]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.saleEmployees])
  },
  methods: {
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchEmployees]),
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchSaleEmployees])
  }
})
export default class RevenueByMonth extends Vue {
  @Prop({ type: Number, required: true })
  year!: number;

  formatCurrency = formatCurrency;
  formatDate = formatDate;

  employees!: EmployeeListViewModel[];
  // Type the mapped fetchPosts action.
  fetchEmployees!: () => Promise<void>;
  saleEmployees!: SelectModelOfString[];
  fetchSaleEmployees!: () => Promise<void>;

  orderPaymentStatusOptions: DropdownOption[] = enumToDropdownOptions(
    OrderPaymentStatus
  );

  $refs!: {
    debtSummaryModal: any;
  };

  selectedEmployee = '';
  selectedEmployeeLastYear = '';

  revenueByEmployeeData: RevenueStatisticsByEmployee[] = [];

  monthRevenues: Array<{
    month: number;
    total: number;
  }> = this.getemptyTotalMonthRevenue();

  revenueByMonthItems: Array<{
    dataObject: string;
    dataId: string;
    revenue1: number;
    revenue2: number;
    revenue3: number;
    revenue4: number;
    revenue5: number;
    revenue6: number;
    revenue7: number;
    revenue8: number;
    revenue9: number;
    revenue10: number;
    revenue11: number;
    revenue12: number;
    isMonthTotal: boolean;
  }> = [];

  revenueByEmployeeItems: Array<{
    dataObject: string;
    dataId: string;
    revenue1: number;
    revenue2: number;
    revenue3: number;
    revenue4: number;
    revenue5: number;
    revenue6: number;
    revenue7: number;
    revenue8: number;
    revenue9: number;
    revenue10: number;
    revenue11: number;
    revenue12: number;
    isMonthTotal: boolean;
  }> = [];

  monthRevenuesByEmployee: Array<{
    month: number;
    total: number;
  }> = this.getemptyTotalMonthRevenue();

  get employeeOptions(): DropdownOption[] {
    if (!AuthStorageService.hasManagedPermissions()) {
      const currentUser = AuthStorageService.getAccessTokenInfo();
      if (!currentUser) return [];

      return [
        {
          text: currentUser.displayName,
          value: currentUser.userId
        } as DropdownOption
      ];
    }
    const result: DropdownOption[] = [];
    this.saleEmployees.map((s: SelectModelOfString) =>
      result.push({ text: s.name ?? '', value: s.id })
    );
    return result;
  }

  mounted() {
    const client = new ApiClientFactory().revenueStatisticsClient();
    client.getByEmployee(this.year).then((result) => {
      this.revenueByEmployeeData = result;

      if (!this.employees || this.employees?.length === 0) {
        this.fetchSaleEmployees().then((s) => {
          this.selectedEmployee = this.employees[0].id ?? '';
          this.prepareRevenueByEmployee(this.selectedEmployee);
        });
      } else {
        this.prepareRevenueByEmployee(this.selectedEmployee);
      }

      this.prepareRevenueByMonth();
    });
  }

  prepareRevenueByMonth() {
    const groupByEmployee = this.revenueByEmployeeData.reduce((rv, x: any) => {
      const key = 'employeeId';
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});

    Object.keys(groupByEmployee).forEach((keyItem) => {
      const revenueItemIndex = this.revenueByMonthItems.findIndex(
        (s) => s.dataId === keyItem
      );

      if (revenueItemIndex === -1) {
        const revenueItem = this.getEmptyRevenueByMonthItem();
        const employeeGroupedValue = groupByEmployee[keyItem];
        revenueItem.dataId = keyItem;
        revenueItem.dataObject =
          employeeGroupedValue[0].employeeName === null
            ? '_'
            : employeeGroupedValue[0].employeeName;
        employeeGroupedValue.forEach((element) => {
          const monthKey = 'revenue' + element.month;
          revenueItem[monthKey] += element.total;
          this.calculateMonthTotal(this.monthRevenues, element);
        });

        this.revenueByMonthItems.push(revenueItem);
      } else {
        const employeeGroupedValue = groupByEmployee[keyItem];

        this.revenueByMonthItems[revenueItemIndex].dataId = keyItem;
        this.revenueByMonthItems[revenueItemIndex].dataObject =
          employeeGroupedValue[0].employeeName === null
            ? '_'
            : employeeGroupedValue[0].employeeName;
        employeeGroupedValue.forEach((element) => {
          const monthKey = 'revenue' + element.month;
          this.revenueByMonthItems[revenueItemIndex][monthKey] = element.total;
          this.calculateMonthTotal(this.monthRevenues, element);
        });
      }
    });
  }

  prepareRevenueByEmployee(employeeId) {
    this.revenueByEmployeeItems = [];
    this.monthRevenuesByEmployee = this.getemptyTotalMonthRevenue();
    if (employeeId) {
      const groupByPaymentMethod = this.revenueByEmployeeData
        .filter((s) => s.employeeId === employeeId)
        .reduce((rv, x) => {
          const key = 'orderPaymentStatus';
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});

      Object.keys(groupByPaymentMethod).forEach((keyItem) => {
        const revenueItemIndex = this.revenueByEmployeeItems.findIndex(
          (s) => s.dataId === keyItem
        );

        if (revenueItemIndex === -1) {
          const revenueItem = this.getEmptyRevenueByMonthItem();
          const employeeGroupedValue = groupByPaymentMethod[keyItem];

          revenueItem.dataId = keyItem;
          revenueItem.dataObject = this.displayOrderPaymentStatus(
            Number.parseInt(keyItem)
          );
          employeeGroupedValue.forEach((element) => {
            const monthKey = 'revenue' + element.month;
            revenueItem[monthKey] = element.total;
            this.calculateMonthTotal(this.monthRevenuesByEmployee, element);
          });

          this.revenueByEmployeeItems.push(revenueItem);
        } else {
          const employeeGroupedValue = groupByPaymentMethod[keyItem];

          this.revenueByEmployeeItems[revenueItemIndex].dataId = keyItem;
          this.revenueByEmployeeItems[
            revenueItemIndex
          ].dataObject = this.displayOrderPaymentStatus(
            Number.parseInt(keyItem)
          );
          employeeGroupedValue.forEach((element) => {
            const monthKey = 'revenue' + element.month;
            this.revenueByEmployeeItems[revenueItemIndex][monthKey] =
              element.total;
            this.calculateMonthTotal(this.monthRevenuesByEmployee, element);
          });
        }
      });
    }
  }

  getEmptyRevenueByMonthItem() {
    return {
      dataObject: '',
      dataId: '',
      revenue1: 0,
      revenue2: 0,
      revenue3: 0,
      revenue4: 0,
      revenue5: 0,
      revenue6: 0,
      revenue7: 0,
      revenue8: 0,
      revenue9: 0,
      revenue10: 0,
      revenue11: 0,
      revenue12: 0,
      isMonthTotal: false
    };
  }

  getemptyTotalMonthRevenue() {
    return [
      {
        month: 1,
        total: 0
      },
      {
        month: 2,
        total: 0
      },
      {
        month: 3,
        total: 0
      },
      {
        month: 4,
        total: 0
      },
      {
        month: 5,
        total: 0
      },
      {
        month: 6,
        total: 0
      },
      {
        month: 7,
        total: 0
      },
      {
        month: 8,
        total: 0
      },
      {
        month: 9,
        total: 0
      },
      {
        month: 10,
        total: 0
      },
      {
        month: 11,
        total: 0
      },
      {
        month: 12,
        total: 0
      }
    ];
  }

  calculateMonthTotal(monthRevenues, element) {
    const index = monthRevenues.findIndex((s) => s.month === element.month);
    if (index !== -1) {
      monthRevenues[index].total += element.total;
    }
  }

  onEmployeeFilterChange(value) {
    this.prepareRevenueByEmployee(this.selectedEmployee);
  }

  displayOrderPaymentStatus(value) {
    return this.orderPaymentStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.orderPaymentStatusOptions.find((x) => x.value === value)
            ?.text as string
        ).toString()
      : '';
  }

  handleMonthTableClicked(month) {
    //console.log({ month });
    this.$refs.debtSummaryModal.openModal(month, this.year);
  }

  handleEmployeeMonthTableClicked(month) {
    //console.log({ month });
    this.$refs.debtSummaryModal.openModal(
      month,
      this.year,
      this.selectedEmployee
    );
  }
}
