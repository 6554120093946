





























































































































import { RevenueStatisticsByProductCategory } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { DEFAULT_PAGE_SIZE_OPTIONS } from '@/utility/pagination/pagination';
import { formatCurrency, convertCurrencyToNumber } from '@/utility/utils';
import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox,
  BPagination
} from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import Pagination from '@/@core/components/paginations/Pagination.vue';

// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox,
    BPagination,
    Pagination
  }
})
export default class RevenueByProductTable extends Vue {
  @Prop({ type: Boolean, required: true })
  byMonth!: boolean;

  //@Prop({ type: Array, required: true })
  data: RevenueStatisticsByProductCategory[] = [];

  //Vuex

  // Methods will be component methods
  formatCurrency = formatCurrency;

  get pageSizeOptions() {
    return DEFAULT_PAGE_SIZE_OPTIONS;
  }

  pagingParams: {
    itemsPerPage: number;
    page: number;
  } = {
    itemsPerPage: 20,
    page: 1
  };

  totalItem = 0;

  get fields() {
    return [
      {
        key: 'index',
        label: this.$t('index'),
        thClass: 'th-color',
        thStyle: { width: '2%' }
      },
      {
        key: 'categoryName',
        label: 'Sản phẩm',
        thClass: 'th-color',
        tdClass: 'text-align-left'
      },
      {
        key: 'totalPrice',
        label: 'Tổng tiền',
        thClass: 'th-color'
      },
      {
        key: 'totalProduct',
        label: 'Tỉ lệ %',
        thClass: 'th-color'
      }
    ];
  }

  mounted() {
    this.fetchData();
  }

  updatePagination(value) {
    this.pagingParams = { ...this.pagingParams, ...value };
    this.fetchData();
  }

  fetchData() {
    if (this.byMonth) {
      this.fetchRevenuebyProductInMonth();
    } else {
      this.fetchRevenuebyProductInWeek();
    }
  }

  fetchRevenuebyProductInWeek() {
    const client = new ApiClientFactory().revenueStatisticsClient();
    client
      .getByProductCategoryInWeek(this.pagingParams.page, null)
      .then((result) => {
        if (result) {
          this.data = result;
          this.totalItem = result[0]?.totalItem ?? 0;
        }
      });
  }

  fetchRevenuebyProductInMonth() {
    const client = new ApiClientFactory().revenueStatisticsClient();
    client
      .getByProductCategoryInMonth(this.pagingParams.page, null)
      .then((result) => {
        if (result) {
          this.data = result;
          this.totalItem = result[0]?.totalItem ?? 0;
        }
      });
  }
}
