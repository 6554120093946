




























































































import ApiClientFactory from '@/api/apiClientFactory';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BTable
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { MODAL_IDS } from '@/utility/constants';
import { OrderListViewModel, OrderStatus } from '@/api/api';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions,
  formatCurrency,
  formatDate
} from '@/utility/utils';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { VueGoodTable } from 'vue-good-table';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BTable,
    VueGoodTable
  }
})
export default class DebtSummaryModal extends Vue {
  @Prop({ type: Number, required: true })
  year!: number;

  month = 0;
  employeeName = '';

  formatCurrency = formatCurrency;
  formatDate = formatDate;
  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);

  orders: OrderListViewModel[] = [];

  fields = [
    {
      label: this.$t('Order'),
      field: 'id',
      thClass: 'order-header-style ml-1 font-weight-500',
      tdClass: 'vertical-align-center',
      width: '6%'
    },
    {
      label: this.$t('Customer'),
      field: 'customerName',
      thClass: 'order-header-style ml-1 font-weight-500',
      tdClass: 'vertical-align-center'
    },
    {
      label: this.$t('Employee'),
      field: 'employeeName',
      thClass: 'order-header-style ml-1 font-weight-500',
      tdClass: 'vertical-align-center'
    },
    {
      label: this.$t('DateCreated'),
      field: 'dateCreated',
      thClass: 'order-header-style',
      tdClass: 'vertical-align-center'
    },
    {
      label: this.$t('TotalPrice'),
      field: 'totalCustomerPay',
      thClass: 'order-header-style'
    },
    {
      label: this.$t('Status'),
      field: 'status',
      width: '10%',
      thClass: 'order-header-style',
      tdClass: 'vertical-align-center'
    }
  ];

  get getModalTitle() {
    if (this.employeeName) {
      return `${this.$t('debt-list-title')}${this.month} - NVKD: ${
        this.employeeName
      }`;
    }
    return `${this.$t('debt-list-title')}${this.month}`;
  }

  onOkClick(bvModalEvent) {
    bvModalEvent.preventDefault();
    //this.onSubmit();
  }

  onCancel() {
    this.employeeName = '';
    this.$bvModal.hide(`${MODAL_IDS.DEBT_SUMMARY_MODAL}-${this.year}`);
  }

  openModal(month, year, employeeId) {
    if (!month || !this.year || month < 0 || this.year < 0) {
      return;
    }

    const client = new ApiClientFactory().orderClient();
    client.getDebtOrdersByMonth(month, employeeId).then((result) => {
      if (result) {
        this.orders = result;
        this.month = month;
        if (employeeId && result.length > 0) {
          this.employeeName = result[0].employeeName ?? '';
        }
        this.$bvModal.show(`${MODAL_IDS.DEBT_SUMMARY_MODAL}-${this.year}`);
      }
    });
  }

  displayOrderStatus(value) {
    return this.orderStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.orderStatusOptions.find((x) => x.value === value)?.text as string
        )
      : '';
  }

  getTotalCustomerPay(order: OrderListViewModel) {
    const vatAmount =
      (convertCurrencyToNumber(order.vatAmount) / 100) * order.totalCustomerPay;
    const total =
      convertCurrencyToNumber(order.shippingFee) +
      vatAmount +
      order.totalCustomerPay -
      convertCurrencyToNumber(order.discountAmount);
    return total < 0 ? 0 : total;
  }
}
