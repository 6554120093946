import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormSelectOption
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { RevenueStatisticsByProductCategory } from '@/api/api';
import RevenueByMonth from './RevenueByMonth.vue';
import RevenueByProductTable from './RevenueByProductTable.vue';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeAction,
  EmployeeState
} from '@/store/employee/employee.module-types';
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    RevenueByProductTable,
    RevenueByMonth,
    vSelect
  },
  computed: {
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.employees]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.saleEmployees])
  },
  methods: {
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchEmployees]),
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchSaleEmployees])
  }
})
export default class RevenueStatisticsList extends Vue {
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  revenueByProductInWeek: RevenueStatisticsByProductCategory[] = [];
  revenueByProductInMonth: RevenueStatisticsByProductCategory[] = [];

  get currentYear() {
    return new Date().getFullYear();
  }
}
